#dashboard{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    width: 100%;
}
#dashboard .dashboard-root{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;
}

.btn-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #fff;
    user-select: none;
}
.btn-bar svg{
    fill: #506dad;
    width: 22px;
}
.btn-bar:hover{
    /* background-color: #fff;
    color: #000;*/
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
    min-width: 160px;
}
.ant-dropdown-menu{
    margin-top: 5px!important;
}
.btn-action{
    margin: .3125rem 0px .3125rem 1px;
    -webkit-appearance: button;
    border-radius: 30px;
    box-shadow: 0 2px 2px 0 rgba(80, 109, 173,.14), 0 3px 1px -2px rgba(80, 109, 173,.2), 0 1px 5px 0 rgba(80, 109, 173,.12);
    background-color: rgb(80, 109, 173);
    color: #fff;
    cursor: pointer;
    border: none;
    padding: 8px 20px;
    position: relative;
    font-size: .8rem;
    min-width: auto;
    text-align: center;
    -webkit-transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1);
    transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1);
    min-height: auto;
    will-change: box-shadow,transform;
    line-height: 1.42857143;
    white-space: nowrap;
    font-weight: 500;
    touch-action: manipulation;
    text-transform: none;
    letter-spacing: 0;
    vertical-align: middle;
    box-sizing: border-box;
    display: inline-flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    overflow: visible;
    font-size: 13px;
}
.sidebar{
    height: 100%;
    padding: 15px 10px 0px;
    background-color: #fff;
    /* border-right: 1px solid #fff; */
    z-index: 10;
    overflow-y: auto;
    scroll-behavior: smooth;
}
.sidebar ul{
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
}
.sidebar ul li{
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: flex-start;
    text-decoration: none;
    padding-left: 16px;
    padding-right: 16px;
    color: #666;
    position: relative;
}
.sidebar ul li .text{
    justify-content: space-between;
    font-size: 14px;
    margin-left: 10px;
}
.sidebar ul li .info{
    color: #fff;
    display: inline-block;
    padding: 5px 12px;
    font-size: 13px;
    text-align: center;
    line-height: 1;
    border-radius: 12px;
    vertical-align: baseline;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ff9800;
}
.sidebar ul li img{
    color: #000;
    width: auto;
    height: auto;
    display: block;
    overflow: visible;
    max-width: 30px;
    max-height: 26px;
    min-height: 26px;
    width: 26px
}
.sidebar ul li i{
    color: #6d9dce;
    font-size: 26px;
    padding-right: 5px;
    width: 26px;
}
.sidebar li.head-menu{
    color: rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
    list-style: none;
    font-weight: 500;
    font-size: 15px;
}
.sidebar ul a.active li{
    background-color: #eef2f5;
    border-radius: 5px;
}
.sidebar ul a.active{
    pointer-events: none;
}
.sidebar ul a:not(.active):hover li{
    background-color: #eef2f5
}
.dashboard-container{
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eef2f5;
    position: relative;
    overflow: auto;
}
.dashboard-container .content-m{
    position: absolute;
    top: 0px;
    /* bottom: 0px; */
    left: 0px;
    /* right: 0px; */
    /* overflow: hidden; */
    min-height: 100%;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
}
.view-error{
    align-items: center;
}
.dashboard-container .content-m .list-c{
    /* overflow-y: auto; */
    width: 100%;
    min-height: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    background: #fff;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
}
.list-m {
    display: flex;
    flex-direction: column;
    overflow-y: hidden!important;
}
.dashboard-container .list-connect .item-data{
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    word-wrap: break-word;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 30px;
    border-radius: 20px;
    margin-bottom: 30px;
    width: 100%;
    margin-bottom: 15px;
    padding: 5px 10px;
    overflow: initial;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
}
.list-connect .item-data .menu-tool{
    display: flex;
    align-items: center;
    justify-content: center;
}
.list-connect .item-data .menu-tool-hide{
    display: none;
}
.list-connect .item-data:hover .menu-tool-hide{
    display: block;
}
.list-connect .item-data .menu-tool-hide a{
    margin-right: 10px;
    padding: 10px 5px;
}
.list-connect .item-data .menu-tool .tool-item{
    margin-left: 15px;
    margin-right: 15px;
}
.list-connect .item-data .item{
    display: flex;
    align-items: center;
    justify-content: center;
}
.list-connect .item-data .item .name-connnect{
    margin-left: 30px;
    font-size: 16px;
    font-weight: 400;
}
.list-connect .item-data .item .avatar-c{
    width: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
}
.list-connect .item-data .item .avatar-c .dot{
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    width: 10px;
    height: 10px;
}
.last_time_connect{
    color: #b3b2b2;
    margin-left: 15px;
    font-weight: 100;
    font-size: 12px;
}
.ant-switch-checked{
    background-color: #506dad!important;
}
.form-modal .ant-modal-content, .form-modal .ant-modal-header{
    border-radius: 10px;
}
.modal-h-antd .ant-modal-content, .modal-h-antd .ant-modal-header{
    border-radius: 10px;
}
.modal-confirm-h .ant-modal-content{

}
.form-modal button, .modal-confirm-h button, .modal-confirm-h .ant-modal-content, .modal-confirm-h .ant-modal-header{
    border-radius: 5px;
}
.form-modal input, .form-modal textarea{
    /* padding-top: 10px!important; */
    /* padding-bottom: 10px!important; */
    /* border-radius: 5px; */
    /* height: 3.0rem!important; */
}
.form-modal .ant-select-selection-search-input{
    /* padding: 0px!important;
    height: 30px!important; */
}
.form-modal .ant-input-suffix{
    display: flex;
    align-items: center;
}
.form-modal .ant-input-password{
    /* padding-top: 0px!important;
    padding-bottom: 0px!important; */
    border-radius: 5px;
}
.form-modal .ant-modal-footer{
    padding: 15px 24px;
}
.form-modal .ant-form-item-explain{
    padding: 8px 0px;
}
.form-modal .list-button{
    display: flex;
    justify-content: flex-end;
    padding-top: 16px
}
.form-modal .list-button button{
    margin-left: 12px
}
.form-modal .ant-modal-body{
    padding-bottom: 10px
}
.form-modal .note p{
    margin: 0px
}
.form-modal .steps-content{
    margin-top: 15px;
    text-align: center
}
.form-modal .ant-steps-item-title{
    font-size: 14px
}
#dashboard .header .logo, .sidebar{
    width: 15%;
}
.status-acc{
    color: #fff;
    border-radius: 5px;
    padding: 2px 5px;
    margin-left: 20px;
}
.description-text {
    max-width: 600px;
    width: 60%;
    font-size: 16px;
    text-align: center;
    padding: 10px;
}
.listdata-img {
    width: 40%;
    max-width: 500px;
}
.empty-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
}
.empty-content button{
    font-size: 14px;
}
.dashboard-role{
    display: flex;
    flex-wrap: nowrap;
    min-height: 100%;
}
.dashboard-role .list-member{
    flex: 0 0 30%;
    background: #fff;
    border-radius: 20px;
    padding: 20px 0px;
    margin: 15px 10px;
    min-height: 100%;
}
.dashboard-role .list-member .title{
    font-weight: bold;
    font-size: 15px;
    padding: 0px 20px;
    margin-bottom: 10px;
}
.dashboard-role .list-member ul li{
    padding: 10px 20px;
    cursor: pointer;
}
.dashboard-role .list-member ul li p{
    margin: 0px;
    margin-left: 10px;
}
.dashboard-role .list-member ul li:hover{
    /* background: rgba(0, 0, 0, 0.06); */
}
.dashboard-role .list-member ul li.active{
    background: #1890ff;
    color: #fff;
}
.dashboard-role .list-member ul li h3{
    font-size: 15px;
}
.dashboard-role .list-member ul li.active h3{
    color: #fff;
}
.dashboard-role .list-role{
    flex: 1;
    margin: 15px;
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    min-height: 100%;
}
.dashboard-role .list-role table th{
    font-size: 15px;
    padding-bottom: 5px;
}
.dashboard-role .list-role table thead tr{
    background-color: #fff!important;
}
.dashboard-role .list-role table tbody tr td{
    padding: 10px;
}
.dashboard-role .list-role tr{
    border-bottom: 1px solid #ccc;
}
.dashboard-role .list-role tr:not(.acc_type){
    background-color: #e0f7fa;
    /* color: #fff; */
}
.dashboard-role .list-role tr.acc_type{
    font-weight: bold;
}
.dashboard-role .list-role tr.disable{
    opacity: 0.5;
    cursor: no-drop;
}
.dashboard-role .list-role tr.disable .ant-checkbox-wrapper{
    pointer-events: none;
}
.button-fixed{
    display: flex;
    margin-top: 10px;
    position: fixed;
    bottom: 10px;
    right: 40px;
}
.button-fixed button{
    box-shadow: 0 3px 6px rgba(0,0,0,0.5);
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.form-company{
    width: 40%;
    margin: auto;
    padding: 5px 20px 0px 20px;
    text-align: center;
}
.form-company .logo img {
    max-width: 55%;
    margin-bottom: 15px;
}
.form-company .logo{
    margin-bottom: 20px;
}
.form-company .logo h2{
    margin-top: 5px;
    color: #666666;
}
.form-company input{
    padding: 12px 10px;
    border-radius: 5px;
}
.form-company button{
    border-radius: 5px;
    padding: 5px 30px;
    height: 35px;
}
.form-company .ant-form-item-explain{
    padding: 5px 0px;
}
.item-box-company{
    width: 50%;
    padding: 0px 10px;
}
.company-item{
    cursor: pointer;
}
.company-item.active{
    background: #506dad!important;
    color: #fff!important;
}
.company-item:not(.active):hover{
    background-color: #f1f1f1!important;
}
.content-m .item-data .role-list .role-item{
    background-color: rgb(244, 67, 54);
    margin: 0px 3px;
    color: rgb(255, 255, 255);
    border: none;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    white-space: nowrap;
    border-radius: 16px;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #e0e0e0;
}
.content-m .item-data .role-list{
    display: flex;
    align-items: center;
}
.content-m .item-data .role-list .role-item{
    display: flex;
    align-items: center;
}
.content-m .item-data .role-list .role-item span{
    overflow: hidden;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: ellipsis; 
    color: #fff;
    font-size: 14px;
}
.content-m .item-data .role-list .role-item i{
    padding: 0px 3px 0px 5px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
}
.content-m .role-item.admin{
    background-color: #f44336!important;
    color: rgb(255, 255, 255);
}
.text-role-admin{
    color: #f44336;
    font-weight: 500;
}
.text-role-manager, .text-role-reporter{
    color: #506dad;
    font-weight: 500;
}
.text-role-sale{
    color: #ff9800;
    font-weight: 500;
}
.content-m .role-item.manager{
    background-color: #506dad!important;
    color: rgb(255, 255, 255);
}
.content-m .role-item.sale{
    background-color: #ff9800!important
}
.content-m .btn-add-role{
    border-radius: 5px;
    width: 36px;
    height: 34px;
    background-color: rgb(80, 109, 173);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    flex: 0 0 36px;
}
.form-code-company .code-show{
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    /* padding-top: 10px; */
    position: relative;
    margin-bottom: 10px;
}
.form-code-company .code-show  label{
    margin: auto;
    font-weight: bold;
    cursor: auto;
    z-index: 1;
    margin: auto;
    font-weight: bold;
    cursor: auto;
    z-index: 1;
    background: #506dad;
    color: #fff;
    border-radius: 5px;
    padding: 2px 15px;
}
.form-code-company .code-show textarea{
    opacity: 0;
    width: 1px;
    height: 1px;
    position: absolute;
    z-index: 0;
}
.ant-dropdown-menu.menu-role li a{
    font-weight: bold;
}
.ant-dropdown-menu.menu-role li a span{
    display: block;
    font-size: 12px;
    font-weight: 100;
}
.list-company-join{
    display: flex;
    flex-wrap: wrap;
}
.list-company-join .item-data{
    /* width: 50%!important; */
}
.list-proxy table{
    background-color: #fff;
    margin-top: 15px;
}
.list-proxy table td, .list-proxy table th, .list-h table td, .list-h table th{
    padding: 10px;
}
.list-h .content-list{
    background-color: #fff;
    margin-top: 15px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.2);
    border-radius: 10px;
    padding: 10px;
    height: 100%;
    position: relative;
}
.content-m .load-spin-box{
    top: 50%;
    transform: translateX(-50%);
}
.form_type_1 .ant-form-item-label{
    /* display: block; */
    /* width: 100%; */
    /* text-align: left; */
}
.tools-container button{
    margin-right: 10px
}
.tools-container .ant-select-selector{
    border-radius: 5px
}
.sort-orders .ant-popover-inner-content .pop-sort-content .item-content:not(:last-child) {
    border-bottom: 1px dashed #e9e9e9;
}
.sort-orders .ant-popover-inner-content .pop-sort-content .item-content {
    padding: 16px 14px;
}
.sort-orders .ant-popover-inner-content .pop-sort-content .item-content .label {
    display: block;
    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
}
.sort-orders .ant-popover-inner-content {
    padding: 0;
}
.ant-popover-inner-content {
    font-size: 14px;
}
.ant-radio-wrapper {
    font-size: 14px !important;
}
.sort-orders .ant-popover-inner-content .pop-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 5px;
    border-top: 1px solid #e8e8e8;
}
.tools-container{
    display: flex;
    justify-content: space-between;
}
.ant-radio-button-wrapper{
    border-radius: 5px!important;
    user-select: none!important
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link, .ant-pagination-item{
    /* border-radius: 20px!important */
}
.list-orders table tbody td{
    padding: 10px 16px!important
}
.update-order-tool .ant-modal-content{
    border-radius: 5px
}
.update-order-tool .scan-orders {
    -webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
    background: #f7f7f7;
}
.order-export-excel-container .order-export-excel-header {
    background-color: #ffffff;
    padding: 10px;
    -webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    border-radius: 3px !important;
    margin-bottom: 10px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.order-export-excel-container .order-export-excel-header button {
    border-radius: 5px
}
.update-order-tool .scan-orders .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    display: flex;
}
.list-statitiscal-channel {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 10px;
}
.list-statitiscal-channel .card{
    width: 25%;
    padding: 5px 10px;
    display: inline-block;
}
.list-statitiscal-channel .card .card-body{
    box-shadow: 0 0 10px -8px;
    padding: 10px 15px 10px 20px;
    background-color: rgb(218, 65, 137);
    border-radius: 5px;
    color: #fff;
}
.list-statitiscal-channel .card .card-body .title{
    font-size: 16px;
}
.list-statitiscal-channel .card .card-body .text-value{
    font-size: 15px;
}
#dashboard .content{
    padding: 20px 30px;
    display: flex;
    flex-direction: column;

}
.table-broadcast tr:hover{
    cursor: pointer;
    background-color: #ecf5ff;
}
.detail-broadcast .detail-run{
    display: flex;
    justify-content: space-between;
}
.detail-broadcast > div{
    margin-bottom: 10px;
}
.list-table{
    height: 100%;
    overflow: auto;
}
.list-table table tr th{
    position: sticky;
    top: 0;
    z-index: 99;
}
.list-c .list-filter-h{
    background: #fff;
    border-radius: 20px;
    padding: 5px;
}
.list-c .list-table{
    height: 100%;
    overflow: auto;
    width: 100%;
    padding: 0px 20px;
}
.list-c .list-top{
    padding: 0px 20px;
}
.list-channel .list-c{
    overflow: hidden!important;
    padding: 10px 0px 60px!important;
}
.table-header .row{
    margin-right: -10px;
    margin-left: -10px;
}
.table-header [class*="col-"]{
    padding-right: 10px;
    padding-left: 10px;
}
/* .table-header .table-filter{
    display: flex;
    justify-content: space-between;
} */

#dashboard .header .btn-bar{
    margin-left: 10px;
}
@media (min-width: 576px) {

}

@media (max-width: 768px) {
    .list-connect .item-data .item .name-connnect{
        font-size: 14px;
        margin-left: 10px;
    }
    .last_time_connect{
        display: none;
    }
    .list-connect .item-data .item .avatar-c{
        width: 30px;
        height: 30px;
    }
    .dashboard-container .list-connect .item-data{
        padding: 5px 10px;
    }
    .dashboard-container .description-text{
        font-size: 14px;
    }
    .dashboard-container .btn-action{
        padding: 5px 20px;
    }
    .form-company{
        width: 100%;
    }
    .dashboard-role{
        flex-wrap: wrap;
    }
    .dashboard-role .list-member, .dashboard-role .list-role{
        flex: 1;
        margin: 0px;
        margin-bottom: 20px;
    }
    .dashboard-role .list-role table th, .dashboard-role .list-member .title{
        font-size: 13px;
    }
    .dashboard-role .list-member ul li{
        padding: 5px 20px;
    }
}

@media (max-width: 992px) {
    #kbyt-face-box .btn-full-screen{
        display: none!important;
    }
    .sidebar ul li .text, .sidebar ul li .info, .sidebar .head-menu{
        /* display: none; */
    }
    .sidebar ul li{
        /* justify-content: center; */
    }
    #dashboard .sidebar{
        /* width: 56px!important;
        padding: 10px 0px 0px; */
        width: 100%;
        position: fixed;
        top: 51px;
        left: 0;
        transform: translateX(100%);
        transition: 0.3s all ease-in-out;
        visibility: hidden;
        z-index: 99;
        padding-bottom: 100px;
    }
    #dashboard .header .logo span{
        display: none;
    }
    #dashboard .header .btn-bar-home{
        display: flex!important;
    }
    .dashboard-container .content-m{
        padding: 15px;
    }
}
@media (max-width: 1366px) {
    #dashboard .header .logo, .sidebar{
        width: 20%;
    }
}

#dashboard .sidebar.show{
    visibility: initial;
    transform: translateX(0);
}
